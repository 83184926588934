<template>
    <div>
      <b-form>
          <b-row class="mb-1">
              <b-col>
                  <h2>
                      Busca la finca de carbono a visualizar
                  </h2>
              </b-col>
          </b-row>

          <b-row>
              <b-col md="1.5" xl="2" class="mb-1">
                  <b-form-group label="Nombre Finca" label-for="basicInput">
                      <b-form-input style="width: 80%" v-model="farmName" placeholder="Digite Nombre"></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col md="1.5" xl="1.5" class="mb-1" offset-md="0.8">
                  <b-form-group label="Nombres Productor" label-for="basicInput">
                      <b-form-input style="width: 80%" v-model="firstName" placeholder="Digite Nombres"></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col md="1.5" xl="1.5" class="mb-1">
                  <b-form-group label="Apellidos Productor" label-for="basicInput">
                      <b-form-input style="width: 80%" v-model="lastName" placeholder="Digite Apellidos"></b-form-input>
                  </b-form-group>
              </b-col>
              <b-col md="1.5" xl="1.5" class="mb-1">
                    <label for="example-datepicker">Fecha de Inicio</label>
                    <b-form-datepicker style="width: 100%" show-decade-nav="true" v-model="startDate" id="example-datepicker2" class="mb-1"/>
              </b-col>
              <b-col md="2" xl="2" class="mb-1">
                    <label for="example-datepicker">Fecha Final</label>
                    <b-form-datepicker style="width: 90%" show-decade-nav="true" v-model="endDate" id="example-datepicker3" class="mb-1"/>
              </b-col>
              <b-col md="1.5" xl="1.5" class="mt-2">
                  <b-button variant="primary" @click="getAllCarbonMeasures">
                      <feather-icon icon="SearchIcon" class="mr-50" />Filtrar
                  </b-button>
              </b-col>
              <b-col md="1" xl="2" class="mt-2 text-right">
                    <b-button v-if="userType != 'AuditorCarbono'" variant="warning" @click="addNewMeasure" block>
                        <feather-icon icon="PlusIcon" class="mr-50" />Captura y Emisión
                    </b-button>
              </b-col>
          </b-row>
      </b-form>

      <b-card-code title="Indicadores de fincas de carbono" no-body>

        <b-table
              ref="selectableTable"
              :fields="fields"
              :items="carbonMeasures"
              responsive
              selectable
              class="mb-0"
              :select-mode="selectMode"
              @row-selected="onRowSelected">
                  <template #cell(farmName)="data">
                      {{ (data.item.farmName) }}
                  </template>

                  <template #cell(productorName)="data">
                      {{ (data.item.firstName) }} {{ (data.item.lastName) }}
                  </template>

                  <template #cell(fromDate)="data">
                      {{ (data.item.fromDate.slice(0, 10)) }}
                  </template>

                  <template #cell(toDate)="data">
                      {{ (data.item.toDate.slice(0, 10)) }}
                  </template>

                  <template #cell(emissions)="data">
                      {{ (data.item.emissions) }}
                  </template>

                  <template #cell(captures)="data">
                      {{ (data.item.captures) }}
                  </template>

                  <template #cell(net)="data">
                      {{ (data.item.net) }}
                  </template>

                  <template #cell(generatedCredits)="data">
                      {{ (data.item.generatedCredits) }}
                  </template>
        </b-table>
      </b-card-code>

      <b-row v-if="this.carbonMeasures.length <= 0" class="justify-content-center mt-3" style="text-align:center">
          <b-col style="justify-content:center" xl="4" md="6" lg="4">
              <b-alert variant="primary" show>
                  <div class="alert-body">
                      <span><strong>No Hay Información para mostrar</strong></span>
                  </div>
              </b-alert>
          </b-col>
      </b-row>

      <b-modal centered ref="buttons-carbon-measure-modal" hide-footer title="¿Que deseas hacer?">
            <b-button class="mt-2" variant="primary" block @click="editCarbonMeasure">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Editar Captura y Emisión</span>
            </b-button>
            <b-button class="mt-2" variant="danger" block @click="deleteCarbonMeasure">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Eliminar Captura y Emisión</span>
            </b-button>
        </b-modal>

      <b-modal size="lg" ref="measureModal" centered hide-footer title="Digite la siguiente información">
            <b-card-text>
                <b-form>                  
                    <b-row>
                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Nombre Finca" label-for="basicInput">
                            <select v-model="carbonFarmID" id="dropdown">
                            <option v-for="item in carbonFarms" :key="item.carbonFarmID" :value="item.carbonFarmID">{{ item.farmName }}</option>
                            </select>
                            </b-form-group>
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <label for="example-datepicker">Fecha Desde</label>
                            <b-form-datepicker :show-decade-nav="true" v-model="fromDate" id="example-datepicker2" class="mb-1"/>
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <label for="example-datepicker">Fecha Hasta</label>
                            <b-form-datepicker :show-decade-nav="true" v-model="toDate" id="example-datepicker2" class="mb-1"/>
                        </b-col>
                    </b-row>

                    <b-row>                      
                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Emisiones" label-for="basicInput">
                                <b-form-input v-model="emissions" placeholder="Digite" />
                            </b-form-group>
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Capturas" label-for="basicInput">
                                <b-form-input v-model="captures" placeholder="Digite" />
                            </b-form-group>
                        </b-col>

                        <b-col md="4" xl="4" class="mb-0">
                            <b-form-group label="Créditos Generados" label-for="basicInput">
                                <b-form-input v-model="generatedCredits" placeholder="Digite" />
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row class="justify-content-end">
                        <b-col v-if="!processing" class="mt-1 mb-1" md="12" lg="12">
                            <b-button variant="primary" @click="saveCarbonMeasure" block>
                                Guardar
                            </b-button>
                        </b-col>
                        <b-col v-if="processing" class="mt-1 mb-1" md="12" lg="12">
                            <b-button variant="primary" disabled block>
                                <b-spinner small label="Cargando..." />
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card-text>
      </b-modal>
    </div>
</template>

<script>

import {
  BLink, BButton, BImg, BSpinner, BRow, BCol, BFormInput, BFormTextarea, BFormGroup,
  BTable, BAlert, BBadge, BFormFile, BForm, BFormSelect, BFormCheckbox, BCardText, BFormDatepicker
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils';
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import Cleave from 'vue-cleave-component';
import axios from '@axios'
export default {
  components: {
      BAlert,
      BLink,
      BButton,
      BCardText,
      BImg,
      BSpinner,
      BBadge,
      BRow,
      BCol,
      BForm,
      BFormInput,
      BFormTextarea,
      BFormGroup,
      BFormFile,
      BTable,
      BCardCode,
      BFormSelect,
      BFormCheckbox,
      BFormDatepicker,
      Cleave,
  },
  data() {
      return {
          selectMode: 'single',
          fields: [
              { key: 'farmName', label: 'Nombre finca' },
              { key: 'productorName', label: 'Nombre productor' },
              { key: 'fromDate', label: 'Desde' },
              { key: 'toDate', label: 'Hasta' },
              { key: 'emissions', label: 'Emisiones' },
              { key: 'captures', label: 'Capturas' },
              { key: 'net', label: 'Neto' },
              { key: 'generatedCredits', label: 'Créditos generados' },
          ], 
          carbonMeasures: [{}],
          carbonFarms: [{}],
          farmName: null,
          productorName: null,
          selectedCarbonMeasure: {},
          fromDate: null,
          toDate: null,
          emissions: null,
          captures: null,
          net: null,
          generatedCredits: null,
          startDate: null,
          endDate: null,
          carbonMeasureID: null,
          carbonFarmID: null,
          processing: null,
          userType: '',
      }
  },
  created() {
      var userData = getUserData();
      this.userType = userData.userType;
      this.getAllCarbonMeasures()
      this.getAllCarbonFarms()
  },

    watch:{
      startDate: function(val) { this.startDate = val; },
      endDate: function(val) { this.endDate = val; },
    },

  methods: {
    getAllCarbonMeasures() {
        axios.get('/CarbonMeasure/getAllCarbonMeasures', {
            params: {
            farmName: this.farmName, firstName: this.firstName, lastName: this.lastName, startDate: this.startDate, endDate: this.endDate,
            }
        })
        .then(response => {
            this.carbonMeasures = response.data;
        })
    },

    getAllCarbonFarms() {
        axios.get('/CarbonFarm/GetAllCarbonFarms', {
            params: {
            }
        })
        .then(response => {
            this.carbonFarms = response.data;
        })
    },
      
    onRowSelected(items) {
        if (this.userType != 'AuditorCarbono') {
            this.selectedCarbonMeasure = items[0];
            if (this.selectedCarbonMeasure) this.$refs['buttons-carbon-measure-modal'].show()
        }
    },

    addNewMeasure() {
        this.processing = false;
        this.carbonMeasureID = undefined;
        this.fromDate = null,
        this.toDate = null,
        this.emissions = null,
        this.captures= null,
        this.generatedCredits = null,
        this.$refs['measureModal'].show()
    },
    
    saveCarbonMeasure() {
        this.processing = true;
        var payload = {
            carbonMeasureID: this.carbonMeasureID,
            carbonFarmID: this.carbonFarmID,
            fromDate: this.fromDate,
            toDate: this.toDate,
            emissions: Number(this.emissions),
            captures: Number(this.captures),
            generatedCredits: Number(this.generatedCredits)
        }

        axios.post('/CarbonMeasure/SaveCarbonMeasure', payload)
            .then(response => {
                this.processing = false;                
                this.$refs['buttons-carbon-measure-modal'].hide();
                this.$refs['measureModal'].hide();
                this.$bvToast.toast("La información ha sido guardada", { title: 'Ok', variant: 'success', solid: true })
                this.getAllCarbonMeasures();
            })
            .catch(error => {
                this.processing = false;
                if (error.response) {
                    this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                    this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
    },
      
      editCarbonMeasure() {        
        this.carbonMeasureID = this.selectedCarbonMeasure.carbonMeasureID;
        this.carbonFarmID = this.selectedCarbonMeasure.carbonFarmID;
        this.fromDate = this.selectedCarbonMeasure.fromDate;
        this.toDate = this.selectedCarbonMeasure.toDate;
        this.emissions = this.selectedCarbonMeasure.emissions;
        this.captures = this.selectedCarbonMeasure.captures;
        this.generatedCredits = this.selectedCarbonMeasure.generatedCredits;
        
        this.getAllCarbonMeasures();
        this.$refs['measureModal'].show()
      },
        
      deleteCarbonMeasure(){            
            axios.get('/CarbonMeasure/DeleteCarbonMeasure', { params: { carbonMeasureID: this.selectedCarbonMeasure.carbonMeasureID }})
            .then(response => {
                this.$refs['buttons-carbon-measure-modal'].hide();
                window.location.reload()
            })
            .catch(error => {
                this.processing = false;
                if(error.response){
                    this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                    this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
      }
  }
}

</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-misc.scss';
</style>